export const getOnlyDate = (fullDate: Date, atpNoDateFound: string) => {
  if (fullDate) {
    const getDate = new Date(fullDate);
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    if (getDate.getTime() < todayDate.getTime()) {
      return atpNoDateFound;
    }

    const year = getDate.getFullYear();
    const month = getDate.getMonth() + 1;
    const day = getDate.getDate();

    return `${day < 10 ? "0" : ""}${day}.${
      month < 10 ? "0" : ""
    }${month}.${year}`;
    // return `${year}/${month < 10 ? "0" : ""}${month}/${
    //   day < 10 ? "0" : ""
    // }${day}`;
  }
  return "";
};
